.adSlot {
  background-color: #f0f0f0;
  padding: 1rem;
  text-align: center;
  border-radius: 4px;
}

.adSense {
  display: inline-block;
}

.adSlot p {
  margin: 0;
  font-size: 0.8rem;
  color: #666;
}

.top, .bottom, .middle {
  margin-top: 0;
  max-height:90px;
  height:90px;
  width:728px;
}

.interstitial {
  width:300px;
  height:250px;
}

@media (max-width: 768px) {
  .top {
    max-height:250px;
    height:250px;
    max-width: 100%;
  }
  .bottom {
    max-height:250px;
    height:250px;
    max-width: 100%;
  }
  
  .middle {
    max-height:250px;
    height:250px;
    max-width: 100%;
  }
}
