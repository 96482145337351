/* App.module.css */

:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --accent-color: #e74c3c;
  --background-color: #ecf0f1;
  --text-color: #34495e;
  --link-color: #007bff;
  --border-color: #dee2e6;
}

.app {
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.6;
}

.mainContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.hero {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 50px 0;
  margin-bottom: 30px;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.ctaButton {
  display: inline-block;
  background-color: var(--secondary-color);
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #5a6268;
}

.infoSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.infoCard {
  flex-basis: calc(33.333% - 20px);
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.infoCard h2 {
  color: var(--primary-color);
  margin-bottom: 15px;
}

.faq {
  margin-bottom: 30px;
}

.faqItem {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}

.faqItem h3 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.countryList {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.countryList li {
  flex-basis: calc(25% - 10px);
  margin: 5px;
}

.countryList a {
  display: block;
  color: var(--link-color);
  text-decoration: none;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.countryList a:hover {
  background-color: var(--primary-color);
  color: white;
}

.countryGrid {
  display: flex;
  flex-wrap: wrap;
  max-width: 70%;
}

@media (max-width: 768px) {
  .infoCard {
    flex-basis: 100%;
  }

  .countryList li {
    flex-basis: calc(50% - 10px);
  }

  .countryGrid {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
  }
}
