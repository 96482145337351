:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --accent-color: #e74c3c;
  --background-color: #f4f7f9;
  --text-color: #34495e;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', 'Arial', sans-serif;
  line-height: 1.8;
  color: var(--text-color);
  background-color: var(--background-color);
  font-size: 16px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

header .container {
  padding: 0 30px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', 'Arial', sans-serif;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h1 {
  font-size: 3em;
  color: var(--primary-color);
}

h2 {
  font-size: 2.5em;
  color: var(--secondary-color);
}

p {
  margin-bottom: 1.5rem;
}

a, .span-link {
  color: var(--primary-color);
  text-decoration: none;
  transition: var(--transition);
}

a:hover, .span-link:hover {
  color: var(--accent-color);
}

.span-link {
  cursor: pointer;
}

ul, ol {
  padding-left: 40px;
}

.button {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: var(--transition);
  box-shadow: var(--box-shadow);
}

.button:hover {
  background-color: var(--accent-color);
  transform: translateY(-2px);
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.card:hover {
  transform: translateY(-5px);
}

/* Responsive design */
@media (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 720px;
  }
  
  body {
    font-size: 15px;
  }
  
  h1 {
    font-size: 2.5em;
  }
  
  h2 {
    font-size: 2em;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
  
  body {
    font-size: 14px;
  }
  
  h1 {
    font-size: 2.2em;
  }
  
  h2 {
    font-size: 1.8em;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 13px;
  }
  
  h1 {
    font-size: 2em;
  }
  
  h2 {
    font-size: 1.6em;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

/* Accessibility */
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

/* Print styles */
@media print {
  body {
    font-size: 12pt;
    line-height: 1.5;
  }

  .container {
    width: 100%;
    max-width: none;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }
}