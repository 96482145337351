/* Modern, sleek styles for DUNS number website */

:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --accent-color: #e74c3c;
  --background-color: #ecf0f1;
  --text-color: #34495e;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

header {
  background-color: var(--primary-color);
  color: white;
  padding: 1rem 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

header h1 {
  margin: 0;
  font-size: 2.5rem;
}

header a {
  color: var(--secondary-color);;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: var(--accent-color);
}

main {
  padding: 2rem 0;
}

.hero {
  background-color: var(--secondary-color);
  color: white;
  padding: 4rem 1.5rem;
  text-align: center;
}

.hero h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color);
  color: white;
  padding: 0.8rem 1.5rem;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #c0392b;
}

#duns-submit:disabled {
  background-color: gray;
}

.info-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
}

.info-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  flex-basis: 30%;
}

.info-card h3 {
  color: var(--primary-color);
  margin-top: 0;
}

footer {
  background-color: var(--secondary-color);
  color: white;
  text-align: center;
  padding: 1rem 0;
  margin-top: 2rem;
}

.country-list-country {
  border: 1px solid gray;
  padding:10px;
  margin: 10px;
  border-radius: 5px;
}

/* Responsive design */
@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  header h1 {
    font-size: 1.5rem;
  }

  .info-card {
    flex-basis: auto;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  header h1 {
    font-size: 1.5rem;
  }

  .info-card {
    flex-basis: auto;
    margin-bottom: 20px;
  }
}
